/** @jsx jsx */
import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import { screenSize, colors, quicksandFont } from "../../utils/css/themes";
import { jsx, css } from "@emotion/react";
import SocialImages from "../Helpers/SocialImages";
import FooterImage from "../../utils/images/Footer/skdFooterImage.png";
import { useUrlParse } from "../../utils/useUrlParse";

const FooterMain = styled.div`
  background-color: #005285;
  border-radius: 69% 78% 50% 50% / 54% 97% 0% 0%;
  display: flex;
  margin-right: -11rem;
  margin-left: -11rem;
  padding-right: 11rem;
  padding-left: 11rem;
  flex-direction: ;

  @media (max-width: ${screenSize.largeTablet}) {
    border-top-right-radius: 0;
    border-top-left-radius: 59em 9.5em;
    padding-top: 3rem;
  }
`;

const ComponentContainer = styled.div`
  text-align: center;
  padding: 0 0.9rem;
  margin: 1rem auto 2rem auto;
  @media (min-width: ${screenSize.largeTablet}) {
    margin: 3rem auto 2rem auto;
    text-align: left;
    width: 1160px;
    padding: 0 1rem;
  }
  @media (min-width ${screenSize.largePhone}) {
    width: 100%;

    padding: 0 2rem;
  }
`;

const BlockTop = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${screenSize.largeTablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const DividerLineDesktop = styled.div`
  display: none;
  @media (min-width: ${screenSize.largeTablet}) {
    display: block;
    max-width: 100%;
    border-top: 1px solid white;
    opacity: 0.2;
    margin: 1.75rem 0;
  }
`;

const DividerLineMobile = styled.div`
  display: block;
  width: 100%;
  border-top: 1px solid white;
  opacity: 0.2;
  margin: 2rem 0;
  @media (min-width: ${screenSize.largeTablet}) {
    display: none;
  }
`;

const BlockBottom = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: ${screenSize.largeTablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SKDImage = styled.div`
  width: 175px;
  height: 55px;
  margin: 0 auto 1rem;
  @media (min-width: ${screenSize.largeTablet}) {
    margin: 0.5rem 0 0 0;
  }
`;

const Nav = styled.div`
  color: white;
  font-family: ${quicksandFont.regular};
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-align: center;
  width: 282px;
  margin: 0 auto 2rem;
  @media (min-width: ${screenSize.largeTablet}) {
    width: fit-content;
    padding-top: 2.5rem;
    margin: 0;
  }
  @media (max-width: ${screenSize.smallPhone}) {
    width: 100%;
  }
`;

const LinkContainer = styled.span`
  :not(:last-child) {
    &:after {
      padding: 0 0.5rem;

      content: "|";
    }
  }
`;

const LinkStyle = css`
  color: white;
  text-decoration: none;
`;

const ContactButton = css`
  width: 270px;
  height: 40px;
  border-radius: 2rem;
  background-color: white;
  text-decoration: none;
  padding: 0.75rem 1rem;

  text-transform: uppercase;
  font-family: ${quicksandFont.bold};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  color: ${colors.blue};
`;

const FooterContainer = styled.footer`
  overflow: hidden;
  z-index: 2;
  position: relative;
  flex-shrink: 0;
  margin-top: -5rem;
  background-color: rgba(255, 0, 0, 0);
`;

const Footer = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      wpMenu(name: { eq: "Footer" }) {
        name
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
    }
  `);

  const footerInfo = data.wpMenu.menuItems;

  return (
    <FooterContainer>
      <FooterMain>
        <ComponentContainer>
          <BlockTop>
            <SKDImage>
              <img
                alt="logo image"
                src={FooterImage}
                width="159px"
                height="49px"
              ></img>
            </SKDImage>
            <Nav>
              {footerInfo.nodes.map((link: any, i: number) => (
                <LinkContainer key={i}>
                  <Link css={LinkStyle} to={useUrlParse(link.path)}>
                    {link.label}
                  </Link>
                </LinkContainer>
              ))}
            </Nav>
          </BlockTop>

          <DividerLineDesktop />
          <BlockBottom>
            <div>
              <SocialImages />
            </div>
            <DividerLineMobile />
            <div>
              <Link css={ContactButton} to="/about-us/dream-team/team-3/">
                {/* {toggleLanguage(
                  "Meet our generous sponsors",
                  "Conoce a nuestros generosos patrocinadores"
                )} */}
                {"Contact Us!"}
              </Link>
            </div>
          </BlockBottom>
        </ComponentContainer>
      </FooterMain>
    </FooterContainer>
  );
};

export default Footer;
